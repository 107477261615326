export const navItems = [
  {
    text: 'Picker',
    path: '/controller'
  },
  {
    text: 'Library',
    path: '/library'
  },
  {
    text: 'Tunes To Learn',
    path: '/tunesiwanttolearn'
  },
  {
    text: 'Help',
    path: '/help'
  },
  {
    text: 'Settings',
    path: '/settings'
  },
  {
    text: 'Contact Us',
    path: '/contact'
  },
]