import styled from 'styled-components';

export const LoadingStyled = styled.div`
  position: relative;
  padding-top: ${props => props.embedded ? '0px' : '25px'};
  text-align: ${props => props.embedded ? 'start' : 'center'};
  
  p {
    margin-top: 1rem;
    font-size: 1.3rem;
  }
`

export const Spinner = styled.div`
  color: official;
  display: inline-block;
  position: relative;
  width: ${props => {
    let modifier = 4;
    if (props.size) { modifier = props.size }
    return (modifier * 13.5) + 'px'
  }};
  height: ${props => {
    let modifier = 4;
    if (props.size) { modifier = props.size }
    return (modifier * 13.5) + 'px'
  }};

  div {
    transform-origin: ${props => {
    let modifier = 4;
    if (props.size) { modifier = props.size }
    const amount = (modifier * 6.5) + 'px';
    return amount + ' ' + amount;
  }};
    animation: lds-spinner 1.2s linear infinite;
  }

  div:after {
    content: " ";
    display: block;
    position: absolute;
    top: ${props => {
    let modifier = 4;
    if (props.size) { modifier = props.size }
    return (modifier * .5) + 'px'
  }};
    left: ${props => {
    let modifier = 4;
    if (props.size) { modifier = props.size }
    return (modifier * 6) + 'px'
  }};
    width: ${props => {
    let modifier = 4;
    if (props.size) { modifier = props.size }
    return (modifier * 1) + 'px'
  }};
    height: ${props => {
    let modifier = 4;
    if (props.size) { modifier = props.size }
    return (modifier * 3) + 'px'
  }};
    border-radius: 20%;
    background: #000;
  }
  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
` 